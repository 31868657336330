/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.css';
@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
@import 'ag-grid-community/dist/styles/ag-theme-balham.css';
@import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
@import 'ag-grid-community/dist/styles/ag-theme-material.css';

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

html body {
  background-color: rgba(44, 44, 44, 1);
  color: #ffffff;
}

/*
*  Tab Menu CSS
*/

.tab-container {
  width: 100%;

  .tab-menu {
    width: 100%;
    background: transparent;
    display: flex;
    border: 0px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0px;

    .tab-menu-item {
      text-align: center;
      border-width: 0;
      display: flex;
      cursor: pointer;
    }
  }

  .tab-content {
    width: 100%;
    min-height: 50px;
    position: relative;

    .action-buttons {
      display: flex;
    }
  }
}

/*
*  Breadcrumb CSS
*/

ol.breadcrumb {
  color: inherit;
  background-color: inherit;
  margin: 0px;

  li.breadcrumb-item {
    color: inherit;

    a {
      color: inherit;
    }
  }
}

/*
*  Fieldset CSS
*/

div.fieldset-container {
  position: relative;
  border: 1px solid transparent;
  border-radius: 2px;

  .fieldset {
    width: 100%;

    .header {
      padding: 8px 10px;

      .title {
        margin: 0px;
      }
    }

    .content {
      padding: 10px;
    }
  }
}

/*
*  Card CSS
*/

.card {
  display: flex;
  flex-wrap: wrap;

  .card-body {
    width: 100%;
    padding: 0px;
    background-color: rgba(44, 44, 44, 1);
  }
}

/*
*  Search-box CSS
*/
div.search-box {
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;

  div.search-icon {
    position: absolute;
    right: 12px;
    top: 7px;
  }
}

/*
*  radio-box CSS
*/
.radio-container {
  width: 100%;
  display: flex;

  .radio-item {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}

.modal-backdrop {
  z-index: 1 !important;
  opacity: 0.5;
}

.modal-dialog {
  margin-top: 11vh !important;
  max-width: 550px !important;
}

.validaion-error-wrapper {
  border: 1px solid #ff0000 !important;
  border-radius: 5px !important;
}

.multiselect-dropdown-container {
  height: fit-content;
  padding: 2px 6px;
}

.ng-select {
  top: 100%;
  margin-top: -1px;
  border: 1px solid #444;
  box-shadow: 0 1px #0000000f;
  left: 0;
  padding: 5px;
  max-height: 120px !important;
  position: static !important;
}

.ng-value {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 2px;
  color: #FFFFFF;
  border-radius: 2px;
  margin-right: 5px;
  padding: 1px 5px;
  border: 1px solid #444;
}

.ng-select.new-recording {
  top: 100%;
  margin-top: 0px;
  border: none !important;
  padding: 5px;
}

.new-recording .ng-value {
  border : none !important;
  padding-top: 2px; 
}

.ng-value-icon {
  margin-top: -2px;
  padding-left: 6px;
  float: right;
  font-size: 14px;
}

ng-select.sort .ng-value {
  border: unset !important;
  font-size: 14px;
  font-weight: 500;
  padding: 1px 6px;
  color: #d2d2d2;
}

ng-select.sort {
  top: 0% !important;
  border-radius: 4px;
}

ng-dropdown-panel {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: #e6e6e6;
  margin-top: -1px;
  background-color: #2C2C2C;
  box-shadow: 0 1px #0000000f;
  padding: 5px;
}

.new-recording ng-dropdown-panel {
  right: 5px;
  width: 90% !important;
  margin: 4px;
}

.share-video-dropdown ng-dropdown-panel {
  width: 63% !important;
  margin: 4px;
  margin-left: -4px;
}

@media only screen and (max-width: 560px) {
  .share-video-dropdown ng-dropdown-panel {
    width: 60% !important;
  }
}

@media only screen and (max-width: 510px) {
  .share-video-dropdown ng-dropdown-panel {
    width: 55% !important;
  }
}

@media only screen and (max-width: 450px) {
  .share-video-dropdown ng-dropdown-panel {
    width: 50% !important;
  }
}

@media only screen and (max-width: 410px) {
  .share-video-dropdown ng-dropdown-panel {
    width: 42% !important;
  }
}

.sort ng-dropdown-panel {
  left: 0;
  border: 1px solid rgb(68, 68, 68, 1);
}

.ng-option {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #2C2C2C;
  color: #FFF;
  padding: 8px 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin: 4px 2px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #463D35;
  color: #FFFFFF;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #FFFFFF;
  background-color: #956942;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 600;
}

.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.ng-placeholder {
  color: #636363
}

.ng-select .ng-arrow-wrapper {
  width: 25px;
  padding-right: 5px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #FFFFFF transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  top: -2px;
  border-color: transparent transparent #FFFFFF;
  border-width: 0 5px 5px;
}

/* For WebKit browsers */
.ng-dropdown-panel ::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.ng-dropdown-panel ::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 10px;
}

.modal-header {
  padding: 1rem 1rem 0px 1rem;
  border-bottom: none;
  font-size: 24px;
}

.modal-body {
  padding: 0 1rem 1rem 1rem;
}


.modal-close-icon {
  font-size: 20px;
  margin-top: 2px;
}

input[type="text"] {
  color: #FFF;
}

.modal-footer {
  border-top: none !important;
  margin-top: -16px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 20px;
}

.ng-value-container {
  overflow-y: auto;
  max-height: 100px !important;
}

.ng-value-container::-webkit-scrollbar {
  width: 4px !important;
  background-color: transparent;
}

.ng-value-container::-webkit-scrollbar-thumb {
  background-color: #2C2C2C;
  border-radius: 10px;
}

.ng-clear-wrapper {
  margin-left: 4px !important;
}

.dropdown-menu {
  background-color: rgb(68, 68, 68, 1);
  z-index: 9999;
  padding: 0;
  min-width: fit-content !important;
  border-radius: 10px;
  margin: 0 0 0 30px;
}

.dropdown-item {
  color: #ffffff;
  width: fit-content;
  border-radius: 10px;
}

.dropdown-item:active,
.dropdown-item:hover {
  background-color: rgb(68, 68, 68, 1);
  color: rgba(242, 153, 74, 1);
}

.cursor-pointer {
  cursor: pointer;
}

.btn-modal-close {
  width: 140px;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  font-family: inherit;
  color: rgba(242, 153, 74, 1) !important;
  border: 1px solid rgba(242, 153, 74, 1);
  border-radius: 20px;
}

.modal-content {
  background-color: rgb(51, 51, 51, 1) !important;
  color: #FFFFFF !important;
  border-radius: 8px !important;
}

.modal-header-icon-container {
  margin-top: 2px;
}

button#start,
button#share,
button#save-recording,
button#restart-recording,
button#cancel-recording,
button#delete-recording,
button#delete-recording-permanently,
button#uploading,
button#upload-rec-close {
  background-color: rgba(242, 153, 74, 1);
  color: #ffffff;
  width: 140px;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  font-family: inherit;
  border-radius: 20px;
}

.popup-info-message {
  color: #999;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.action-danger {
  background-color: #FF4155 !important;
}

div#videoPreviewModal,
div#mediaDevicesModal,
div#shareVideoModal,
div#uploadRecModal {
  backdrop-filter: brightness(0.5);
}

.form-control {
  color: #FFF  !important;
  background-color: rgba(44, 44, 44, 0.60) !important;
  border-radius: 4px;
  border: 1px solid #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.form-control:focus {
  border-color: #F2994A !important;
  outline: none !important;
  box-shadow: none;
}

.divider {
  border-bottom: 1px solid #444;
}

.hidden {
  display: none !important;
}